/* 
* Forms 
*/

input[type="text"],
input[type="password"],
input[type="email"] {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  outline-color: #0E76BC;
  box-shadow: 0 0 2pt 1pt #0E76BC;
}

span.dollar-inline {
  display: inline-block;
  position: relative;
}

span.dollar-inline::before {
  content: "$";
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  position: absolute;
  left: 8px;
  top: 34%;
  transform: translateY(-50%);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type="number"] {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 10px 10px 10px 20px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

.ccard {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 10px 10px 25px 10px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

input[type="text"]:disabled {
  color: rgb(171, 171, 171);
}

input[type="file"] {
  font-family: 'Montserrat', sans-serif;
  padding: 0px 0px 10px 0px;
}

textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 150px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

select {
	display: block;
  font-size: 16px;
  line-height: 20px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	color: #444;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%; 
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#ececec 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .5em top 50%, 0 0;
	background-size: .5em auto, 100%;
}

select::-ms-expand {
	display: none;
}

select:hover {
	border-color: #888;
}
select option {
	font-weight:normal;
}

.yup-error {
  color: rgb(252, 36, 36) !important;
}