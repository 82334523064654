.f-container {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  position: absolute;
  bottom: -150px;
  height: 100px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
