@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,900&display=swap);
/***************************************** 
* Top nav
*****************************************/

/* Large */
@media screen and (min-width: 701px) {
  .nav-container {
    margin: 0 auto;
    width: 80%;
  }
}


/* Small */
@media screen and (max-width: 700px) {
  .nav-container {
    margin: 0 auto;
  }
}

.top-nav {
  width: 100%;
  background: white;
  height: 70px;
  position: fixed;
  margin: 0 0 0 0;
  z-index: 1;
}

.nav-link {
  position: relative;
  text-decoration: none;
  height: 70px;
  line-height: 70px;
  white-space: nowrap;
  color: #0E76BC;
  padding: 0px 10px 0px 10px;
}

.nav-link:hover:after {
  content: " ";
  position: absolute;
  top: 110%;
  left: 50%;
  width: 30px;
  border: 1px solid #FFF200;
  background-color: #FFF200;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.nav-select:after {
  content: " ";
  position: absolute;
  top: 110%;
  left: 50%;
  width: 30px;
  border: 1px solid #FFF200;
  background-color: #FFF200;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.nav-drop-select:after {
  content: " ";
  position: absolute;
  top: 65%;
  left: 50%;
  width: 30px;
  border: 1px solid #FFF200;
  background-color: #FFF200;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.nav-logo {
  width: 100%;
  height: auto;
  max-width: 140px;
  padding: 5px;
}

/* 
* Drop down 
*/
.top-dropdown {
  position: relative;
  display: inline-block;
}

.top-dropdown-content {
  display: none;
  position: absolute;
  top: 101.5%;
  right: -50%;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.top-dropdown-content a {
  color: gray;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-nav-link {
  position: relative;
  text-decoration: none;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;
  color: gray;
  text-decoration: none;
  display: block;
  text-align: center;
}

.top-dropdown-content .dropdown-nav-link:hover:after {
  content: " ";
  position: absolute;
  top: 65%;
  left: 50%;
  width: 30px;
  border: 1px solid #FFF200;
  background-color: #FFF200;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.top-dropdown-content .dropdown-nav-link.top-dropdown-select:after {
  content: " ";
  position: absolute;
  top: 65%;
  left: 50%;
  width: 30px;
  border: 1px solid #FFF200;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%);
}

.top-dropdown:hover .top-dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #0E76BC;
}

/***************************************** 
* Side nav
*****************************************/
/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 16px;
  color: white;
  transition: color 0.2s;
  outline: 0;
  height: 42px;
  line-height: 42px;
  white-space: nowrap;
  padding: 0px 10px 0px 10px;
}

/* Change color on hover */
.bm-item:hover {
  color: #FFF200;
}

/* The rest copied directly from react-burger-menu docs */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 15px;
  top: 15px;
  outline: 0;
}

.white-fill {
  fill: white;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/* General sidebar styles */
.bm-menu {
  position: relative;
  background: #0E76BC;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  text-align: center;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #0E76BC;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
/* .bm-overlay {
  background: rgba(52, 52, 52, 0.3);
} */

/* 
* Drop down 
*/
.side-dropdown {
  position: relative;
  display: inline-block;
}

.side-dropdown-content {
  display: none;
  position: absolute;
  top: 105%;
  right: -5%;
  background-color: #ffffff;
  min-width: 150px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 5px;
  font-size: 16px;
}

.side-dropdown-content a {
  color: gray;
  text-decoration: none;
  display: block;
  text-align: center;
}

.side-dropdown-content a:hover {
  background-color: #f8f8f844;
  color: #FFF200;
}

.side-dropdown:hover .side-dropdown-content {
  display: block;
}

.side-dropdown:hover .dropbtn {
  background-color: #0E76BC;
}

.f-container {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  position: absolute;
  bottom: -150px;
  height: 100px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

/* Colors */
/* Primary: #0E76BC */
/*  Secondary: #FFF200 */

@font-face {
    font-family: "Agency Regular";
    src: url(/static/media/Agency_Regular.e18fc104.otf);
}

.regular-font {
    font-family: "Agency Regular";
    line-height: 1.6;
    font-size: 16;
}

.h0 {
    font-size: 5em;
    font-family: "Agency Regular";
    color: #0E76BC;
}

h1 {
    font-size: 4em;
    font-family: "Agency Regular";
    color: #0E76BC;
}

h2 {
    font-size: 2em;
    font-family: "Agency Regular";
    color: #0E76BC;
}

h3 {
    font-size: 1.5em;
    padding: 0px !important;
    margin: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    font-family: "Agency Regular";
    color: #0E76BC;
}

@media screen and (max-width: 700px) {
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1em;
        width: 60%;
    }
}

h4 {
    font-family: "Agency Regular";
    color: #0E76BC;
}

h5 {
    font-family: "Agency Regular";
    color: #0E76BC;
}

p {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    font-size: 16;
}

p.indent {
    text-indent: 2.5em;
}

.s-line-height {
    line-height: 1.2;
}

a {
    text-decoration: none;
    color: #0E76BC;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

ul,
ol,
li {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    font-size: 16;
}

label {
    font-family: 'Montserrat', sans-serif;
}

/* Text size */
.xs-text {
    font-size: 8px;
}

.s-text {
    font-size: 12px;
}

.m-text {
    font-size: 16px;
}

.l-text {
    font-size: 20px;
}

.xl-text {
    font-size: 24px;
}

/* Text color */
.grey {
    color: grey !important;
}
.black {
    color: black !important;
}
.blue {
    color: #0E76BC !important;
}
.yellow {
    color: #ffd54c !important;
}
.white {
    color: white !important;
}
.red {
    color: rgb(252, 36, 36) !important;
}
.green {
    color: rgb(19, 204, 13) !important;
}

/* Text hover color */
.text-hover:hover {
    color: #0E76BC  !important;
    cursor: pointer;
}

.text-hover-danger:hover {
    color: rgb(252, 36, 36)  !important;
    cursor: pointer;
}

.text-hover-warn:hover {
    color: #ffd54c  !important;
    cursor: pointer;
}


/* Heading in center of <hr/> */
/* EXAMPLE:
            <h2 className="heading-rule">
            <span>Messages</span>
            </h2>      
*/
.heading-rule {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #0E76BC;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.heading-rule span {
    background: #fff;
    padding: 0 10px;
    color: black;
    font-family: "AvenirNext-Bold" !important;
}



/* Multiline text overflow */
.block-with-text {
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.5em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 4.5em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    padding: 0 1em 0 0;
    margin: 0 -1em 0 0;
}
.block-with-text:before {
    /* points in the end */
    content: "...";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
}
.block-with-text:after {
    /* points in the end */
    content: "";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    width: 1em;
    /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: white;
}

/* Misc */
/* This one is kinda an alignment one too? */
.center-text {
    text-align: center !important; 
}

.underline {
    text-decoration: underline;
}
  
/* Alignment CSS contains styles such as margins, 
   padding, width, containers, and positioning. */
/* -------------------------------------------- */


/* Main alignment (for footer?) */
html {
    position: relative;
    min-height: 100%;
  }
  body {
    margin: 0 0;
    padding: 0;
  }
  

/* 
* Responsive Containers and Widths
*/
/* Large */
@media screen and (min-width: 1181px) {
    .wrapper {
        /* For outer div holding the body */
        margin: 0 auto;
        width: 80%;
        padding: 5% 0 0 0;
    }

    .l-container {
        margin: auto;
        width: 70%;
        padding: 1%;
    }

    .m-container {
        margin: auto;
        width: 50%;
        padding: 1%;
    }

    .s-container {
        margin: auto;
        width: 25%;
        padding: 1%;
    }
    
    .paralax-body {
        padding: 5% 0 5% 0;
        width: 70%
    }
}

/* Medium */
@media screen and (min-width: 561px) and (max-width: 1180px) {
    .wrapper {
        margin: 0 auto;
        width: 70%;
        padding: 10% 0 0 0;
    }

    .l-container {
        margin: auto;
        width: 90%;
        padding: 1%;
    }

    .m-container {
        margin: auto;
        width: 80%;
        padding: 1%;
    }

    .s-container {
        margin: auto;
        width: 70%;
        padding: 1%;
    }
}

/* Small */
@media screen and (max-width: 700px) {
    .wrapper {
        margin: 0 auto;
        width: 90%;
        padding: 15% 0 0 0;
    }

    .l-container {
        margin: auto;
        width: 95%;
        padding: 1%;
    }

    .m-container {
        margin: auto;
        width: 95%;
        padding: 0.5%;
    }

    .s-container {
        margin: auto;
        width: 95%;
        padding: 0.5%;
    }
}

@media screen and (max-width: 700px) {
    .s-width {
        width: 90% !important;
    }

    .m-width {
        width: 90% !important;
    }

    .l-width {
        width: 90% !important;
    }
}

@media screen and (min-width: 701px) {
    .s-width {
        width: 25% !important;
    }

    .m-width {
        width: 50% !important;
    }

    .l-width {
        width: 75% !important;
    }
}


/*
* Positioning
*/
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: center;
}

.right {
    display: block;
    float: right;
}

.left {
    display: block;
    float: left;
}

/* Position attribute added */
.p-container {
    position: relative;
}

.p-top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

.p-top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

.p-bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.p-bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.p-center {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
}

.p-bottom-center {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
}


/* 
* Padding
*/
.no-padding {
    padding: 0px !important;
}

.s-padding-r {
    padding: 0 1% 0 0;
}

.l-padding-r {
    padding: 0 10% 0 0;
}

.s-padding-b {
    padding: 0 0 1% 0;
}

.m-padding-t {
    padding: 5% 0 0 0;
}

.m-padding-r-l {
    padding: 0 5% 0 5%;
}

.m-padding-r {
    padding: 0 5% 0 0;
}

.m-padding-t-b {
    padding: 5% 0 5% 0;
}

.m-padding-b {
    padding: 0 0 5% 0;
}

.l-padding-r-l {
    padding: 0 10% 0 10%;
}

.s-padding {
    padding: 1%;
}

.m-padding {
    padding: 5%;
}

.l-padding {
    padding: 10%;
}


/* 
*  Margin
*/
  
.no-margin {
    margin: 0px !important;
}

.s-margin {
    margin: 1%;
}

.m-margin {
    margin: 5%;
}

.l-margin {
    margin: 10%;
}

.m-margin-t {
    margin: 5% 0 0 0;
}

.s-margin-t-b {
    margin: 1% 0% 1% 0%;
}

.s-margin-b {
    margin: 0% 0% 1% 0%;
}


.l-margin-r {
    margin: 0 15% 0% 0% !important;
}

/*
* Misc 
*/

.inline {
    display: inline; 
}

.horiz-center {
    margin: 0 auto;
}

  
/* 
* Anything styling images
*/
.responsive {
    max-width: 100%;
    height: auto;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Imagine sizing */
img.xsmall {
    width: 100%;
    height: auto;
    max-width: 50px;
    padding: 5px;
}

img.small {
    width: 100%;
    height: auto;
    max-width: 100px;
    padding: 5px;
}

img.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 5px;
}

img.large {
    width: 100%;
    height: auto;
    max-width: 300px;
    padding: 5px;
}

img.xlarge {
    width: 100%;
    height: auto;
    max-width: 450px;
    padding: 5px;
}

/* Icons */
i.small {
    width: 100%;
    height: auto;
    max-width: 100px;
    padding: 5px;
}

i.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 5px;
}

i.large {
    width: 100%;
    height: auto;
    max-width: 300px !important;
    padding: 5px;
}

/* em styling */
img.s-em {
    height: 1em;
}

img.m-em {
    height: 1.25em;
}

img.l-em {
    height: 1.75em;
}

img.xl-em {
    height: 2em;
}

/* Captions */
div.captioned {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.caption {
    display: block;
}

/* Background video */
.bg-media-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.bg-media {
    height: auto;
    vertical-align: middle;
    width: 100vw;
    height: 70vh;
    object-fit: cover;
    margin: -5px;

} 

.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-container iframe  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* White blurred overlay */
.overlay-modal {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    line-height: 1.5;
    max-width: 50%;
    padding: 1rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

/* For small screens */
@media screen and (max-width: 700px) {
    .overlay-modal {
        max-width: 90%;
    }

    video {
        width: 100vw;
        height: 60vh;
    } 
}
  
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlay-modal {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.5);  
    }
    .warning {
      display: none;
    }
  }
  

/* 
* Forms 
*/

input[type="text"],
input[type="password"],
input[type="email"] {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  outline-color: #0E76BC;
  box-shadow: 0 0 2pt 1pt #0E76BC;
}

span.dollar-inline {
  display: inline-block;
  position: relative;
}

span.dollar-inline::before {
  content: "$";
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  position: absolute;
  left: 8px;
  top: 34%;
  transform: translateY(-50%);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type="number"] {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 10px 10px 10px 20px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

.ccard {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  padding: 10px 10px 25px 10px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

input[type="text"]:disabled {
  color: rgb(171, 171, 171);
}

input[type="file"] {
  font-family: 'Montserrat', sans-serif;
  padding: 0px 0px 10px 0px;
}

textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 150px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 4px;
  background-color: white;
  resize: none;
}

select {
	display: block;
  font-size: 16px;
  line-height: 20px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	color: #444;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%; 
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#ececec 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .5em top 50%, 0 0;
	background-size: .5em auto, 100%;
}

select::-ms-expand {
	display: none;
}

select:hover {
	border-color: #888;
}
select option {
	font-weight:normal;
}

.yup-error {
  color: rgb(252, 36, 36) !important;
}
/* 
* Small Buttons 
*/

.disabled{
    opacity: 0.25;
    filter: alpha(opacity=25);
    box-shadow: none;
}

.s-btn {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    color: white;
    background-color: #0E76BC;
    border: 1px solid #0E76BC;
}

.s-btn:hover {
    background-color: rgb(12, 91, 145);
}

.s-btn-inv {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px; 
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    /* differences */
    color: #0E76BC;
    background-color: white;
    border: 1px solid #0E76BC;
}

.s-btn-inv:hover {
    background-color: rgb(12, 91, 145);
    color: white;
}

/* Success */
.s-btn-success {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    color: white;
    background-color: rgb(19, 204, 13);
    border: 1px solid rgb(19, 204, 13);
}

.s-btn-success:hover {
    background-color: rgb(3, 189, 34);
}

.s-btn-success-inv {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px; 
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    /* differences */
    color: rgb(45, 190, 40);
    background-color: white;
    border: 1px solid rgb(19, 204, 13);
}

.s-btn-success-inv:hover {
    background-color:rgb(3, 189, 34);
    color: white;
}

/* Danger */
.s-btn-danger {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;

    color: white;
    background-color: rgb(252, 36, 36);
    border: 1px solid rgb(252, 36, 36);
}

.s-btn-danger:hover {
    background-color: rgb(255, 88, 88);
}

.s-btn-danger-inv {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;

    color: rgb(252, 36, 36);
    background-color: white;
    border: 1px solid rgb(252, 36, 36);
}

.s-btn-danger-inv:hover { 
    background-color: rgb(255, 88, 88);
    color: white;
}

/* Warning */
.s-btn-warning {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;

    color: white;
    background-color: #FFD54C;
    border: 1px solid #FFD54C;
}

.s-btn-warning:hover {
    background-color: #FFD54C;;
}

.s-btn-warning-inv {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    background-color: white;
    border: 1px solid #FFD54C;
    border-radius: 4px;
    color: #FFD54C;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
}

.s-btn-warning-inv:hover {
    background-color: #FFD54C;
    color: white;
}


/* AirBNB */
.s-btn-airbnb {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    color: white;
    background-color: #ff5a5f;
    border: 1px solid #ff5a5f;
}

.s-btn-airbnb:hover {
    background-color: #ff494f;
}

/* VRBO */
.s-btn-vrbo {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 5px 16px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
    color: white;
    background-color: #1c4695;
    border: 1px solid #1c4695;
}

.s-btn-vrbo:hover {
    background-color: #0e214b;
}

/*
* Medium Buttons 
*/

.m-btn {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    background-color: #0E76BC; /* Green */
    border: 1px solid #0E76BC;
    border-radius: 4px;
    color: white;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
}

.m-btn:hover {
    background-color: rgb(12, 91, 145);
}

.m-btn-inv {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    text-decoration: none;
    background-color: white; /* Green */
    border: 1px solid #0E76BC;
    border-radius: 4px;
    color: #0E76BC;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline-width: 0;
}

.m-btn-inv:hover {
    background-color: rgb(12, 91, 145);
    color: white;
}

/* Close Button */
.close {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 32px;
    height: 32px;
    opacity: 0.6;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  
/* 
* Misc css classes manipulating objects
*/

/* Table */
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

tr:hover {background-color:#f5f5f5;}

/* Cards */
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    overflow: hidden;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


/* Borders */
.s-border {
    border-style: solid;
    border-width: 1px;
    border-color: #0E76BC;
}

.m-border {
    border-style: solid;
    border-width: 3px;
    border-color: #0E76BC;
}

.l-border {
    border-style: solid;
    border-width: 5px;
    border-color: #0E76BC;
}

.left-border {
    border-left: 6px solid #0E76BC;
    padding: 10px 0px 10px 5px;
}

/* Tooltip */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #0E76BC;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    top: 15px;
    z-index: 1;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

/* Misc */
.hover:hover {
    border-style: solid;
    border-width: 1px;
    border-color: #ffd54c;
    cursor: pointer;
}

.display-block {
    display: block;
}

span {
    font-family: 'Montserrat', sans-serif;
}

div {
    font-family: 'Montserrat', sans-serif;
}

.background-blue {
    background: #0E76BC
}

.overflow-div {
    overflow-x:auto;
}

.rounded {
    border-radius: 50%;
}  

hr {
    border-top: 0.1px solid grey;
}

.hide {
    display: none;
}

.show-cursor {
    cursor: pointer;
}

.clearfix {
    overflow: auto;
}




