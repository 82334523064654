/* 
* Anything styling images
*/
.responsive {
    max-width: 100%;
    height: auto;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Imagine sizing */
img.xsmall {
    width: 100%;
    height: auto;
    max-width: 50px;
    padding: 5px;
}

img.small {
    width: 100%;
    height: auto;
    max-width: 100px;
    padding: 5px;
}

img.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 5px;
}

img.large {
    width: 100%;
    height: auto;
    max-width: 300px;
    padding: 5px;
}

img.xlarge {
    width: 100%;
    height: auto;
    max-width: 450px;
    padding: 5px;
}

/* Icons */
i.small {
    width: 100%;
    height: auto;
    max-width: 100px;
    padding: 5px;
}

i.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 5px;
}

i.large {
    width: 100%;
    height: auto;
    max-width: 300px !important;
    padding: 5px;
}

/* em styling */
img.s-em {
    height: 1em;
}

img.m-em {
    height: 1.25em;
}

img.l-em {
    height: 1.75em;
}

img.xl-em {
    height: 2em;
}

/* Captions */
div.captioned {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.caption {
    display: block;
}

/* Background video */
.bg-media-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.bg-media {
    height: auto;
    vertical-align: middle;
    width: 100vw;
    height: 70vh;
    object-fit: cover;
    margin: -5px;

} 

.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-container iframe  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* White blurred overlay */
.overlay-modal {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    line-height: 1.5;
    max-width: 50%;
    padding: 1rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

/* For small screens */
@media screen and (max-width: 700px) {
    .overlay-modal {
        max-width: 90%;
    }

    video {
        width: 100vw;
        height: 60vh;
    } 
}
  
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .overlay-modal {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.5);  
    }
    .warning {
      display: none;
    }
  }
  
