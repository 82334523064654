/* 
* Misc css classes manipulating objects
*/

@import "~react-image-gallery/styles/css/image-gallery.css";

/* Table */
table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

tr:hover {background-color:#f5f5f5;}

/* Cards */
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    overflow: hidden;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


/* Borders */
.s-border {
    border-style: solid;
    border-width: 1px;
    border-color: #0E76BC;
}

.m-border {
    border-style: solid;
    border-width: 3px;
    border-color: #0E76BC;
}

.l-border {
    border-style: solid;
    border-width: 5px;
    border-color: #0E76BC;
}

.left-border {
    border-left: 6px solid #0E76BC;
    padding: 10px 0px 10px 5px;
}

/* Tooltip */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #0E76BC;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    top: 15px;
    z-index: 1;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

/* Misc */
.hover:hover {
    border-style: solid;
    border-width: 1px;
    border-color: #ffd54c;
    cursor: pointer;
}

.display-block {
    display: block;
}

span {
    font-family: 'Montserrat', sans-serif;
}

div {
    font-family: 'Montserrat', sans-serif;
}

.background-blue {
    background: #0E76BC
}

.overflow-div {
    overflow-x:auto;
}

.rounded {
    border-radius: 50%;
}  

hr {
    border-top: 0.1px solid grey;
}

.hide {
    display: none;
}

.show-cursor {
    cursor: pointer;
}

.clearfix {
    overflow: auto;
}



